

// Your Slick Slider initialization code here
$('.owl-carousel').owlCarousel({
    loop: false,
    margin:10,
    nav:false,
    dots:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:1
        }
    }
})

/** Equal Height Function for Category Box images */
const images = document.querySelectorAll('.grid-img img');
let maxHeight = 0;
images.forEach(img => {
  const imgHeight = img.clientHeight;
  if (imgHeight > maxHeight) {
    maxHeight = imgHeight;
  }
});
images.forEach(img => {
  img.style.height = `${maxHeight}px`;
  img.style.objectFit = 'contain';
});